<template>
  <a-drawer
  class="drawer-custom"
    title="SUMMARY OF ASSIGNMENTS"
    placement="left"
    :width="isMobile ? 320 : 480"
    :closable="true"
    :visible="visible"
    @close="onClose"
    v-if="dataDrawer.studentsChart"
  >
    <p>Name : {{ dataDrawer.Name }}</p>
    <a-divider />
    <p>Total Assignment : {{ dataDrawer.studentsChart.totalAssignment }}</p>
    <a-divider />
    <p>Avg of Assignment : {{ dataDrawer.studentsChart.avgAssignment }}</p>
    <a-divider />
    <!-- {{dataDrawer.ListAssignment}} -->
    <!-- <LineChart :chartData="chartData" /> -->
    <a-menu v-model="current" mode="horizontal">
      <a-menu-item key="list">
        <a-icon type="unordered-list" />Assignments by List
      </a-menu-item>
      <a-menu-item key="chart">
        <a-icon type="line-chart" />Assignments by Chart</a-menu-item
      >
    </a-menu>
    <div v-if="current[0] === 'chart' && chartData.labels.length" class="mt-4">
      <LineChart :chartData="chartData" />
    </div>
    <div v-else>
      <div class="assignment-list">
        <div
          class="list d-flex flex-column"
          v-for="(item, index) in dataDrawer.dataSesi.rows"
          :key="index"
        >
          <div class="d-flex flex-row align-items-start">
            <div>
              <p class="font-weight-bold">#{{ number(index) }}.</p>
            </div>
            <div class="d-flex flex-column ml-3">
              <div>
                <p>{{ parseMoment(item.tanggal_sesi) }} ({{ item.tipe_assignment }})</p>
              </div>
              <div>
                <p>
                  <span class="font-weight-bold">Grade : </span
                  >{{ item.tugas_nilai }}
                </p>
              </div>
              <div>
                <p>
                  <span class="font-weight-bold">Feedback : </span>{{ item.feedback }}
                </p>
              </div>
            </div>
            <div class="ml-auto">
              <a-button @click.prevent="toGrading(item.id)" type="primary">
                See Detail
              </a-button>
            </div>
          </div>
          <a-divider />
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import moment from 'moment'
const LineChart = () => import('@/components/app/LineChart')
export default {
  name: 'DrawerReport',
  components: {
    LineChart,
  },
  props: {
    visible: {
      type: Boolean,
    },
    dataDrawer: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      current: ['list'],
    }
  },
  methods: {
    number(index) {
      return ++index
    },
    parseMoment(date) {
      return moment(date).format('DD MMMM YYYY')
    },
    onClose() {
      this.$emit('on-close', true)
    },
    toGrading(idSesi) {
      this.$router.push({ name: 'Grading Assignments Teacher', params: { id: idSesi } })
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss" scoped>
.drawer-custom {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    .ant-drawer-content {
      background: black;
    }
  }
  .assignment-list {
    margin-top: 20px;
    height: 100%;
    overflow-y: auto;
    // background: gray;

    .list {
      margin-top: 10px;
    }
  }
}
</style>
